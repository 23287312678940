import React, { ReactNode } from 'react'
import clsx from 'clsx'
import Loader from './Loader'
import { Link } from '@remix-run/react'

export interface ButtonProps {
  secondary?: boolean
  danger?: boolean
  disabled?: boolean
  border?: boolean
  icon?: ReactNode
  href?: string
  to?: string
  submit?: boolean
  fullWidth?: 'sm' | 'md' | boolean
  size?: 'small' | 'medium' | 'large'
  value?: string
  name?: string
  children?: ReactNode
  loading?: boolean
  target?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  role?: React.ButtonHTMLAttributes<
    HTMLButtonElement | HTMLAnchorElement
  >['role']
}

const Button: React.FC<ButtonProps> = ({
  secondary = false,
  danger = false,
  disabled = false,
  submit = false,
  fullWidth = false,
  border = true,
  size = 'medium',
  icon,
  href,
  to,
  name,
  value,
  children,
  loading,
  target = '_self',
  onClick,
  ...props
}) => {
  const commonClasses = clsx(
    'rounded-sm flex items-center justify-center gap-2',
    {
      'w-full md:w-auto': fullWidth === 'sm',
      'w-full lg:w-auto': fullWidth === 'md',
      'w-full': typeof fullWidth === 'boolean' && fullWidth,
      'w-auto': !fullWidth,
      'inline-flex': href,
      flex: !href,
      'px-3 py-1': size === 'small' && border,
      'btn-sm': size === 'small',
      'px-6 py-3': size === 'medium' && border,
      'btn-md': size === 'medium',
      'px-8 py-4': size === 'large' && border,
      'btn-lg': size === 'large',
      'text-neutral-40-placeholder bg-neutral-10-background cursor-no-drop':
        disabled && !secondary,
      'text-neutral-30-inactive bg-neutral-0-white cursor-no-drop':
        disabled && secondary,
      'border-neutral-20-borders-dividers border-1':
        disabled && secondary && border,
      'text-white bg-primary-30-default hover:bg-primary-40-hover active:bg-primary-50':
        !danger && !secondary && !disabled,
      'focus:ring-4 focus:ring-inset focus:ring-primary-20':
        !danger && !disabled && size != 'small',
      'focus:ring-2 focus:ring-primary-20':
        !danger && !disabled && size == 'small',
      'text-white bg-danger-30-primary hover:bg-danger-40':
        danger && !secondary && !disabled && size != 'small',
      'bg-white  text-primary-30-default': !danger && secondary && !disabled,
      'border-1 border-neutral-20-borders-dividers hover:border-primary-30-default hover:border-1':
        !danger && secondary && !disabled && border,
      'bg-white border-1 border-neutral-20-borders-dividers text-danger-30-primary focus:ring-4 focus:ring-inset focus:ring-danger-20 hover:border-danger-30-primary hover:border-1':
        danger && secondary && !disabled && size != 'small',
      'border-1 border-neutral-20-borders-dividers hover:border-danger-30-primary hover:border-1':
        danger && secondary && !disabled && border && size != 'small',
      'hover:border-none border-none': !border,
    },
  )

  if (href) {
    return (
      <a
        className={commonClasses}
        href={disabled ? undefined : href}
        target={target}
        onClick={onClick}
        {...props}
      >
        {icon && <span className="inline-block">{icon}</span>}
        <span>{children}</span>
      </a>
    )
  }

  if (to) {
    return (
      <Link
        className={commonClasses}
        to={to}
        target={target}
        onClick={onClick}
        {...props}
      >
        {icon && <span className="inline-block">{icon}</span>}
        <span>{children}</span>
      </Link>
    )
  }

  return (
    <button
      className={clsx(commonClasses, 'relative')}
      type={submit ? 'submit' : 'button'}
      disabled={disabled || loading}
      name={name}
      value={value}
      onClick={onClick}
      {...props}
    >
      {icon && !loading && <span className="inline-block">{icon}</span>}
      <span className={clsx(loading ? 'opacity-0' : '')}>{children}</span>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader width="w-4" height="h-4" />
        </div>
      )}
    </button>
  )
}

export default Button
