interface LoaderProps {
  width?: string
  height?: string
}

export default function Loader({
  width = 'w-10',
  height = 'h-10',
}: LoaderProps) {
  return (
    <div className="absolute inset-0 flex justify-center items-center bg-white/60 z-50">
      <div className={`sk-chase ${width} ${height}`}>
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
      </div>
    </div>
  )
}
